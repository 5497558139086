/* input label css */
.inputLabel {
  font-family: "Rubik", sans-serif;
  /* color: rgb(100, 100, 100); */
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0px;
}
.common_star_color {
  color: red;
  padding-left: 2px;
}
.common_select_option_value {
  width: 100%;
  background-color: #fff;
  border-radius: 30px;
  border: 3px solid #ececec;
  color: rgb(100, 100, 100);
  padding: 0 15px;
  display: block;
  position: relative;
  margin-bottom: 0px;
}

.common_select_option_value > select {
  background: transparent;
  font-size: 14px;
  line-height: 18px;
  border: 0 !important;
  border-radius: 0;
  height: 31px;
  width: 100%;
  color: blue;
  outline: none !important;
  text-decoration: none !important;
}

.common_select_option_value:focus-within {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  width: 100%;
  background-color: #fff;
  border-radius: 30px;
  border: 1px solid #ddd;

  color: rgb(100, 100, 100);
  padding: 0 15px;
  display: block;
  position: relative;
}

.common_select_option_value > select:disabled {
  background-color: #e9ecef;
  opacity: 1;
}
/* phone input css */
.common_phoneinput_border .react-tel-input .form-control {
  /* height: 46px !important; */
  width: 100% !important;
  background: rgba(35, 34, 35, 1);
  color: #fff;

  border: 1px solid #ececec !important;
  padding-left: 48px !important;
  border-radius: 8px !important;
}

.common_phoneinput_border .react-tel-input .flag-dropdown {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  background: rgba(35, 34, 35, 1);
}

.common_phoneinput_border .react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  /* background-color: #f5f5f5 !important; */
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}