body {
  margin: 0;
  padding: 0;
  background-color: #1d2634;
  color: #9e9ea4;
  font-family: 'Montserrat', sans-serif;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex_space_around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.main-title {
  display: flex;
  justify-content: space-between;
}

.card-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.daily_take_refresh_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon_Container {
  border-radius: 30px;
  margin-left: 10px;
  width: 120px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border: .5px solid #F00;
}

.icon_Container_white {
  border-radius: 30px;
  margin-left: 10px;
  width: 120px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border: .5px solid #f7f7f7;
}

.icon_Container:hover,
.icon_Container_white:hover {
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;
}

img:hover {
  cursor: pointer;
}

.red_icon {
  vertical-align: middle;
  line-height: 1px;
  font-size: 22px;
  color: #F00;
}

.red_16_text {
  color: #F00;
  font-weight: 500;
  font-size: 14px;
  margin-left: 10px;
}

.white_icon {
  vertical-align: middle;
  line-height: 1px;
  font-size: 22px;
  color: #f7f7f7;
}

.white_16_text {
  color: #f7f7f7;
  font-weight: 500;
  font-size: 14px;
  margin-left: 10px;
}

.home_red_icon {
  vertical-align: middle;
  line-height: 1px;
  font-size: 30px;
  color: #F00;
}

.heder_right_white_icon {
  vertical-align: middle;
  line-height: 1px;
  font-size: 30px;
  color: #fff;
  margin-right: 30px;
}

.notification_black_icon {
  vertical-align: middle;
  line-height: 1px;
  font-size: 25px;
  color: '#000';
  margin-right: 30px;
}

.heder_right_white_icon:hover {
  cursor: pointer;
}

.icon {
  vertical-align: middle;
  line-height: 1px;
  font-size: 20px;
}

.icon_header {
  vertical-align: middle;
  line-height: 1px;
  font-size: 26px;
}

.icon,
.icon_header {
  margin-right: 5px;
}

.close_icon {
  color: red;
  margin-left: 30px;
  margin-top: 10px;
  cursor: pointer;
}

.grid-container {
  display: grid;
  grid-template-columns: 130px 140px 1fr 1fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas:
    'header header header header'
    'sidebar childSideBar main main';
  height: 100vh;
}

.withOut_childsideBar_grid_container {
  display: grid;
  grid-template-columns: 130px 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas:
    'header header header header'
    'sidebar main main main';
  height: 100vh;
}

@media screen and (max-width: 990px) {
  .withOut_childsideBar_grid_container {
    grid-template-columns: 0 1fr 1fr 1fr !important;
  }

  .header_right_flex {
    margin-right: 8px;
  }

  .header-right {
    padding-right: 0px;
  }

  .header-menu-divider-between-items {
    width: "100%";
    height: 2px;
    background-color: gray;
  }

  .notification_black_icon {
    font-size: 40px;
  }

  .flex-align-center {
    align-items: flex-start;
  }

  .bell_popup {
    position: absolute;
    /* display: none; */
  }

  .dropdown .dropdown-menu .dropdown-item-mobile-only {
    width: 100%;
    padding: 6px 12px;
    margin-bottom: 2px;
    border-radius: 4px;
    font-size: 14px;
    color: rgb(100, 100, 100);
    text-decoration: none;
    display: inline-block;
    letter-spacing: 0.5px;
    clear: both;
    position: relative;
    outline: 0;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
  }

  .header {
    padding-right: 0px;
  }

  .icon_Container_white {
    display: none;
  }
}

/* Header  */
.header {
  grid-area: header;
  height: 80px;
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 30px;
  background-color: #232223;
  box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.25);
}

.menu-icon {
  display: none;
}

/* End Header  */

/* Sidebar  */
#sidebar {
  grid-area: sidebar;
  margin-top: 3px;
  height: 100%;
  background-color: #232223;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}



.flex-space-between>span {
  display: none;
}

.sidebar-brand {
  margin-top: 15px;
  font-size: 20px;
  font-weight: 700;
}

.sidebar-list {
  padding: 0;
  list-style-type: none;
}

.sidebar-list-item {
  padding: 18px 20px 18px 20px;
  font-size: 18px;
  border-width: 2px;
  border: .5px solid #000;

}

.sidebar-list-item>a {
  text-decoration: none;
}

.sidebar-list-item:hover {
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;
}

.sidebar-red-list-item {
  padding: 20px 10px 10px 20px;
  font-size: 18px;
  align-items: center;
}

.sidebar-red-list-item:hover {
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;
}

.sidebar_red_icon {
  vertical-align: middle;
  line-height: 1px;
  font-size: 32px;
  color: #F00;
  justify-content: center;
  align-items: center;
  margin-left: 25px;
}

.sidebar_red_text {
  font-weight: 500;
  text-align: center;
  font-size: 17px;
  color: #F00;
  margin-top: 5px;
  margin-right: 10px;
}

.sidebar_white_icon {
  vertical-align: middle;
  line-height: 1px;
  font-size: 32px;
  color: #fff;
  /* justify-content: center;
  align-items: center; */
  margin-left: 25px;
}

.sidebar_white_text {
  color: #fff;
  font-weight: 500;
  text-align: center;
  font-size: 17px;
  margin-top: 5px;
}

.sidebar-red-list-item>a {
  text-decoration: none;
}

.image_red_color {
  width: 30px;
  height: 30px;
  margin-left: 33px;
  fill: #F00;
}

.sidebar-responsive {
  display: inline !important;
  position: absolute;
  z-index: 12 !important;
}

/* End Sidebar  */

/* Child_sidebar */
#childSideBar {
  grid-area: childSideBar;
  margin-top: 3px;
  margin-left: 2px;
  /* height: 100vh; */
  background-color: #232223;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  border-width: 2px;
  border: .5px solid #000;
}

.child-sidebar-list {
  padding: 0;
  list-style-type: none;
}

.child-sidebar-list-item {
  padding: 10px 0px 10px 0px;
  font-size: 18px;

}

.child-sidebar-list-item:hover {
  cursor: pointer;
}

.child_sidebar_white_text {
  color: #FFF;
  font-size: 14px;
  /* font-weight: 200; */
  text-align: center;
}

.child_sidebar_red_text {
  color: #F00;
  font-size: 14px;
  /* font-weight: 200; */
  text-align: center;
}

/* End child_sidebar */
/* Main  */
.main-container {
  grid-area: main;
  /* overflow-y: auto; */
  padding: 40px 40px;
  color: rgba(255, 255, 255, 0.95);
  background-color: #1A181A;
}



.main-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin: 15px 0;
}

.card {
  background-color: #232223;
  padding: 20px;
  margin: 20px 0px 0px 0px;
}

.card_container {
  background-color: #232223;
  padding: 10px 0px 10px 0px;
  margin: 20px 0px 0px 0px;
}

.card_container:hover {
  cursor: pointer;
}

.card-inner>.card_icon {
  font-size: 25px;
}

.profile_card {
  background-color: #232223;
  padding: 20px;
  margin: 20px 0px 0px 0px;
}

.profile_text {
  color: #fff;
  text-align: center;
}

.profile_text:hover {
  cursor: pointer;
  color: #F00;
}

.action_main_cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin: 15px 0;
}

@media screen and (max-width: 900px) {
  .action_main_cards {
    grid-template-columns: 1fr;
  }
}

.action_card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #232223;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.large_red_text {
  color: #F00;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.action_text_margin {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mission_main_cards {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #232223;
  margin: 15px 0;
  padding: 10px;
  border: .1px solid #000;
}

.daily_mission_container {
  border: .1px solid #000;
  padding: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

}

.takeAction_container {
  border-radius: 30px;
  width: 75px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: .5px solid #F00;
  margin-right: 10px;
}

.takeAction_text {
  color: #F00;
  font-size: 12px;
}

.claim_container {
  border-radius: 50px;
  width: 60px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  background-color: #28A745;
}

.claim_text {
  color: #fff;
  font-size: 10px;
}

.audience_main_cards {
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  gap: 20px;
  margin: 15px 0;
}

.audience_card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #232223;
  padding: 10px;
}

.flex_text {
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;
}

.charts {
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  /* gap: 20px; */
  margin-top: 30px;
  height: 350px;
}

.rating_main_cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  /* margin: 15px 0; */
}

@media screen and (max-width:900px) {
  .rating_main_cards {
    grid-template-columns: 1fr;
  }
}

.rating_card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #232223;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border: .1px solid #000;
}

.coinscount_text {
  color: #F00;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.clock_red_icon {
  color: #F00;
  /* font-size: 10px; */
}

.red_card {
  display: flex;
  justify-content: space-around;
  background-color: #F00;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.red_card_icon {
  vertical-align: middle;
  line-height: 1px;
  font-size: 35px;
  color: #fff;
  margin-bottom: 20px;
}

/* .resumeViewBarCenter {
  display: block;
  display: flex;
  padding: 15px;
  align-self: center;
} */
.resume_view_progessbar {
  width: 100px !important;
  height: 100px !important;
  /* align-self: center; */
  /* align-items: center;
  justify-content: center;
  display: flex;
  text-align: center; */
}

.shield_container {
  display: flex;
  align-items: center;
  border-bottom: .1px solid #000;
  padding-bottom: 10px;
  padding-top: 10px;
}

.notification_container {
  display: flex;
  align-items: center;
  /* border-bottom: .1px solid #000; */
  padding-bottom: 10px;
  padding-top: 10px;
}

.card_white_text {
  color: #FFF;
  font-weight: 500;
  font-size: 30px;
  text-align: center;
}

/* End Main  */

/* Affiliates */
.invite_everyone_card {
  background-color: #232223;
  margin: 15px 0;
  padding: 10px 20px 10px 20px;
}

.red_large_text {
  color: #F00;
  font-size: 42px;
  font-weight: 600;
  text-align: center;
}

.invite_para_text {
  color: #FFF;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.form-control {
  border-radius: 0px 40px 40px 0px;
  border: 1px solid #D9D9D9;
  background: #232223;
  color: #fff;
}

.btn-outline-secondary {
  border-radius: 0px 30px 30px 0px;
  background: #F00;
  color: #FFF;
  font-size: 14px;
  font-weight: 300;
}

.btn-primary {
  border-radius: 60px;
  background: #F00;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  border: none;
  padding: 5px 30px 5px 30px;
  margin-top: 10px;
}

/* End Affiliates */
/* Support Ticket */
.form-select {
  margin-top: 10px;
  border: 1px solid #FFF;
  background: #232223;
  color: #FFF;
  font-size: 14px;
  font-weight: 300;
}

.bold-placeholder::placeholder {
  font-weight: 400;
  color: #FFF;
}

.form-comment {
  width: 100%;
  margin-top: 10px;
  border-radius: 10px;
}

.input-group {
  /* margin-top: -50px; */
}

.input-group-text {
  margin-top: 10px;
  border: 1px solid #FFF;
  background: #232223;
  color: #FFF;
  font-size: 16px;
  font-weight: 400;
}

.common_phoneinput_border {
  width: '100%';
}

.CircularProgressbar .CircularProgressbar-text {
  /* fill: #3e98c7; */
  /* font-size: 18px; */
  dominant-baseline: middle;
  text-anchor: middle;
}

/* End Support Ticket */
.white_card {
  background-color: #fff;
  padding: 20px;
  margin-top: 20px;
}

.white_card_black_text {
  color: #000;
  font-weight: 500;
}

.membership_border_bottom_flex {
  align-items: center;
  display: flex;
  border-bottom: 1px solid #FFF;
  padding-bottom: 10px;
}

.membership_card {
  background-color: #232223;
  padding: 40px 20px 20px 20px;
  margin: 40px 0px 0px 0px;

}

.membership_center {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.red_membership_card_icon {
  color: #F00;
  font-size: 50px;
  font-weight: 100;
}

.white_bold_text {
  color: #FFF;
  font-size: 24px;
  font-weight: 500;
  margin-top: 10px;
}

.white_700_text {
  color: #FFF;
  font-size: 24px;
  font-weight: 700;
  margin-top: 10px;
}

/* .white_14_500_text{
  color: #FFF;
font-size: 14px;
font-weight: 500;
} */
.membership_green_text {
  color: #28A745;
  font-weight: 500;
}

.red_center_text {
  color: #F00;
  font-weight: 500;
  text-align: center;
  margin-top: 30px;
  font-size: 16px;
}

.No_data_available_center {
  text-align: center;
  margin-top: 10px;
}

.elevation_card {
  padding: 25px 25px 25px 25px;
  background-color: #232223;
  box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.25);
  border-top: .4px solid rgba(0, 0, 0, 0.25);
  border-left: .4px solid rgba(0, 0, 0, 0.25);
  border-right: .4px solid rgba(0, 0, 0, 0.25);
  margin-top: 20px;
}

.reward_green_text {
  color: #00FF3A;
  margin-left: 70px;
  font-size: 14px;
  font-weight: 400;
}

.btn-notAvailable {
  border-radius: 60px;
  background: #F00;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  border: none;
  padding: 10px 30px 10px 30px;
  margin-left: 20px;
}

.btn-outline-primary {
  color: #F00;
  border-radius: 60px;
  border: 1px solid #F00;
  padding-right: 20px;
  padding-left: 20px;
  background: #232223;
  font-size: 14px;
  font-weight: 400;
}

.radion_label_container {
  border: 1px solid #FFF;
  padding: 5px 15px 8px 5px;
}

.form-check-label {
  margin-left: 5%;
}

.form-check-input {
  margin-top: 12px;
}

.topupCheckBox {
  display: block;
  min-height: 1.5rem;
  /* padding-left: 1.5em; */
  margin-bottom: 0.125rem;
}

.video_link_container {
  align-items: center;
  display: flex;
  gap: 5%;
}

.estimated_white_container {
  background-color: #fff;
  margin: 15px 0;
  padding: 10px 20px 5px 20px;
  border-radius: 10px;
}

.estimated_text {
  color: #000;

  /* font-size: 20px; */
  font-weight: 400;
}

/* Medium <= 992px */

@media screen and (max-width: 992px) {
  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      'header'
      'main';
  }

  #sidebar {
    display: none;
  }

  .menu-icon {
    display: inline;
  }

  .flex-space-between>span {
    display: inline;
  }
}

/* Small <= 768px */

@media screen and (max-width: 768px) {
  .main-cards {
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 0;
  }

  .charts {
    grid-template-columns: 1fr;
    margin-top: 30px;
  }
}

/* Extra Small <= 576px */

@media screen and (max-width: 576px) {
  .hedaer-left {
    display: none;
  }
}

.profileAvtar {
  border-radius: 35px;
  font-size: 10px;
}

.today_center {
  /* align-items: center; */
  text-align: center;
}

.container {
  display: flex;

}

.column {
  flex: 1;
  margin: 0 10px;
  text-align: center;
  flex-direction: column;
}

.header-right {
  padding-right: 3% !important;
  padding-left: 3% !important;
  display: flex;
  align-items: center;
}

.header_right_flex {
  display: flex;
  justify-content: space-between;
  /* margin-right: 20px; */
  /* padding-top: 10px; */
}

/* .dropdown-toggle::after {
  display: none;
} */
.header_parameter_color {
  color: rgb(100, 100, 100);
  font-weight: 400 !important;
  font-size: 14px;
  cursor: pointer;
  padding: 0 17px;
  letter-spacing: 0.5px;
  border-radius: 4px;
  line-height: 37px;
}

.header_parameter_color:hover {
  color: #26ae61;
}

.header_user_lineheight {
  line-height: 2 !important;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  margin: 0px 0px 0px -30px;
  padding: 10px;
  display: none;
  position: absolute;
  z-index: 99;
  min-width: 161px;
  border: none;
  background-color: #fff;
  white-space: nowrap;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 14px rgb(0 0 0 / 35%);
  animation: fadeIn 0.4s;
  -webkit-animation: fadeIn 0.4s;
  -moz-animation: fadeIn 0.4s;
  -o-animation: fadeIn 0.4s;
  -ms-animation: fadeIn 0.4s;
}

.dropdown-menu:before {
  position: absolute;
  top: -8px;
  left: 55px;
  display: block;
  content: "";
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0px 9px 8px;
  border-color: transparent transparent #fff;
  box-sizing: border-box;
}

.dropdown:hover .dropdown-menu {
  display: block;
  position: absolute;
  text-align: left;
  top: 100%;
  animation: fadeIn 0.4s;
  -webkit-animation: fadeIn 0.4s;
  -moz-animation: fadeIn 0.4s;
  -o-animation: fadeIn 0.4s;
  -ms-animation: fadeIn 0.4s;
  background: #ffffff;
}

/* .header-menu-divider-between-items {
  width: "100%";
  height: 4px;
  background-color: black;
} */

.dropdown-item-mobile-only {
  display: none;
}

.dropdown .dropdown-menu .dropdown-item {
  width: 100%;
  padding: 6px 12px;
  margin-bottom: 2px;
  border-radius: 4px;
  font-size: 14px;
  color: rgb(100, 100, 100);
  text-decoration: none;
  display: inline-block;
  float: left;
  letter-spacing: 0.5px;
  clear: both;
  position: relative;
  outline: 0;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}

.dropdown .dropdown-item:focus,
.dropdown .dropdown-item:hover,
.dropdown .dropdown-item.active {
  background: #F00;
  color: #fff !important;
  cursor: pointer;
}

.dropdown-item.active,
.dropdown-item:active {
  background: transparent;
}

.dropdown-item:focus,
.dropdown-item:hover,
.dropdown-item.active {
  background: #26ae61;
  color: #fff !important;
}

/* topbar bell popup */
.bell_popup_container {
  position: relative;

}

.bell_popup {
  position: absolute;
  top: 40px;
  right: 0px;
  flex: 1;
  /* width: 450px; */
  /*height: 700px;*/
  max-height: 370px;
  overflow-y: scroll;
  background-color: #fff;
  padding: 10px;
  z-index: 999;
  border-radius: 10px;
  /* display: none; */
}

.notification-center {
  text-align: center;
  color: #F00 !important;
}

.view-all {
  margin-top: 10px;
  border-top: 1px solid #ccc;
  padding-top: 7px;
}

/* Wallet */
.margin_wallet {
  margin-top: 20px;
}

.limit_Budget_Hover {
  background-color: rgb(35, 34, 35);
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #fff;
  border-width: 2px;
}

.limit_Budget_Hover:hover {
  cursor: pointer;
  background-color: #F00;

}

.contain {
  display: flex;
  flex-direction: column;
}

/* .row {
  display: flex;
  justify-content: center;
} */

.col {
  flex: 1;
  /* border: 1px solid #ccc; */
  padding: 10px;
}

.colFlex {
  flex: 1;
}

.payment-label {
  margin-left: 1%;
  line-height: 42px;
}

.table>:not(caption)>*>* {
  background-color: #232223 !important;
  color: #ffffff !important;
}


@media screen and (max-width:900px) {
  aside#childSideBar {
    position: fixed;
    width: 100%;
    background: black;
    /* height: 40px; */
    z-index: 999999999;
    bottom: 0px;

    & ul.child-sidebar-list {
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: center;
    }
  }
}

#tawk-minified {
  position: fixed;
  bottom: 100px !important;
  right: 20px !important;
  z-index: 999999999;
}

#tawk-minified-container {
  position: fixed;
  bottom: 100px !important;
  right: 0 !important;
}